
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminHeaderService {
    private emitChangeSource = new Subject<any>();
    searchEmitted$ = this.emitChangeSource.asObservable();
    constructor(private http: HttpClient) {}
    searchChange(change: any) {
        this.emitChangeSource.next(change);
    }
}

<ng-container *ngIf="inputFormControl">
    <input (blur)="trimValue()" (keydown.space)="keyDown($event)" (keyup)="keyUp($event)" type="text"  [class]="inputClass" [ngClass]="{'input-has-error':inputFormControl.touched && inputFormControl.errors}" placeholder="{{placeholder}}" [formControl]="inputFormControl" />
    <app-element-loader [loader]="uniqueLoader ? 'uniqueLoader' : null"></app-element-loader>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.required">
        {{displayName}} is required.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.minlength">
        {{displayName}} must be at least {{minLength}} characters long.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.maxlength">
        {{displayName}} can be max {{maxLength}} characters long.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalidNumber">
        {{displayName}} should only contain numeric values.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalidAlphanumeric">
        Special characters not allowed
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalidText">
        {{displayName}} should only contain alphabets.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.spaceErrorAlphanumeric">
        {{displayName}} should contain alphabets or numbers
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.spaceErrorText">
        {{displayName}} should contain alphabets
    </div>
    <div class="has-error" *ngIf="inputFormControl.errors?.unique">
        {{displayName}} already exists.
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalidCountryCode">
        Country code is required
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalidPhone">
        {{displayName}} is invalid
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.email">
        {{displayName}} is invalid
    </div>
    <div class="has-error" *ngIf="inputFormControl.touched  && inputFormControl.errors?.unavailable">
        {{displayName}} is already registered
    </div>
</ng-container>
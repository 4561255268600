<div>
    <ngx-intl-tel-input  [cssClass]="{ 'text-input' : 1, 'input-has-error':inputFormControl.touched && inputFormControl.errors}"
        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
        [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true"
        [separateDialCode]="true" [numberFormat]="'Local'" name="contact_number"
        [formControl]="inputFormControl">
    </ngx-intl-tel-input>
</div>
<div class="has-error mt-5p mb-2"
    *ngIf="inputFormControl.touched  && inputFormControl.errors?.required ||inputFormControl.value==false">
    <div>Contact number is required</div>
</div>
<div class="has-error mt-5p mb-2"
    *ngIf="inputFormControl.touched  && inputFormControl.errors?.invalid ||inputFormControl.value==false">
    <div>Contact number is invalid</div>
</div>
<div class="has-error mt-5p mb-2"
    *ngIf="inputFormControl.touched  && inputFormControl.errors?.validatePhoneNumber?.valid==false">
    <div>Contact number is invalid</div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() displayName: any;
  @Input() placeholder: any;
  @Input() label: any;
  @Input() value: any;
  @Input() items: any;
  @Output() valueChange = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  sendResponse($event): void {
    this.valueChange.emit($event?.id ? $event?.id : '');
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { LoadersService } from 'src/app/core/services/loaders.service';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css']
})
export class ButtonLoaderComponent implements OnInit {
  @Input() loader: string;
  @Input() color: string = 'white';
  @Input() size: string = '20';
  @Input() svg: boolean = false;

  constructor(
    public loaders: LoadersService
  ) { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  template: `<p class="text-primary-light line-height-0 f-12">
      ©{{currentYear}} Made in India by Haatch Interactive
    </p>
    <p class="text-primary-light f-12">Version Beta 0.0.1 Build 2762022</p>`,
})
export class VersionComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}

<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation" mode="side" opened>
        <app-admin-nav-bar></app-admin-nav-bar>
    </mat-sidenav>
    <mat-sidenav-content>
        <main>
            <app-admin-header></app-admin-header>
            <router-outlet></router-outlet>
        </main>
        <app-version style="margin-top:200px; bottom: 0;" class="ml-1"></app-version>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent implements OnInit {

  @Input() inputFormControl: FormControl;
  @Input() displayName: any;
  @Input() placeholder: any;
  @Input() inputClass: any;
  @Input() minimumTime: any;
  @Input() pickerType: string = 'calendar';
  constructor() { }
  ngOnInit(): void {
    const date = new Date();
    this.minimumTime = date;
  }

}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { ImageModalComponent } from './image-modal.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  @Input() options: Options;
  @Input() inputFormControl: any;
  @Input() displayName: string;
  @Output() imageUploaded = new EventEmitter<any>();
  @ViewChild('file') file: ElementRef;
  private eventsSubscription: Subscription;

  @Input() events: Observable<void>;

  fileId: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  showModel = false;
  outputImage: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.fileId = this.getRandomId();
    this.eventsSubscription = this.events.subscribe(() => this.triggerUpload());
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  triggerUpload() {
    this.file.nativeElement.click();
  }
  getRandomId() {
    return Math.floor(Math.random() * 1500 + 1);
  }
  fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.outputImage = {
        name: (event.target.files[0].name.substring(0, event.target.files[0].name.lastIndexOf('.'))) + '.png',
        size: event.target.files[0].size,
        type: 'image/png'
      };
      this.imageChangedEvent = event;
      this.openDialog();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      width: '250px',
      data: { imageChangedEvent: this.imageChangedEvent, options: this.options }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.outputImage.data = result;
        this.save();
      }
    });
  }

  save() {
    this.imageUploaded.emit(this.outputImage);
    this.showModel = false;
  }
}
export interface Options {
  buttonName: string;
  aspectRatio: number;
  width: number;
}

<div class="mt-2 " fxLayout="row">
    <div class="search-box" fxFlex="50">
        <form [formGroup]="searchForm">
            <div class="search-icon">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.8394 5.55595C16.1268 7.84337 16.1268 11.552 13.8394 13.8394C11.552 16.1268 7.84337 16.1268 5.55595 13.8394C3.26853 11.552 3.26853 7.84337 5.55595 5.55595C7.84337 3.26854 11.552 3.26854 13.8394 5.55595Z"
                        stroke="#4F5C77" stroke-width="2" />
                    <path d="M13.469 13.469L20 20" stroke="#4F5C77" stroke-width="2" />
                </svg>
            </div>
            <app-input minLength="0" maxLength="120" displayName="Search" placeholder="Search" inputClass="search"
                [inputFormControl]="searchForm.controls['search']" (valueChange)="onValueChange($event)">
            </app-input>
        </form>
    </div>
    <div  fxFlex="50" fxLayoutAlign="end start">
        <div class="flex row header-profile">
            <img [src]="adminUser?.image" onerror="this.src='assets/avatar.png'" alt="" class="avatar small ml-1 mr-1">
            <div class="">
                <button mat-button [matMenuTriggerFor]="beforeMenu" disableRipple>{{adminUser?.first_name?(adminUser.first_name|titlecase):''}} {{adminUser?.last_name?(adminUser.last_name):''}}</button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

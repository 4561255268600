
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';

import { Injectable, ɵConsole } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../services/http-cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    constructor(private httpCancelService: HttpCancelService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
    }
}

import { LoadersService } from './loaders.service';
import { tap, finalize } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class CommonService {
    protected loader: LoadersService;
    public constructor(
        injector: Injector,
    ) {
        this.loader = injector.get(LoadersService);
    }
    loaderCall(func: any, loader = null): Observable<any> {
        if (loader) {
            this.loader.setComponentLoader(loader);
            return func.pipe(
                tap(
                    res => {
                        this.loader.removeComponentLoader(loader);
                    },
                    err => {
                        this.loader.removeComponentLoader(loader);
                    },
                    () => {
                        this.loader.removeComponentLoader(loader);
                    }
                ),
                finalize(() => {
                    this.loader.removeComponentLoader(loader);
                })
            );
        } else {
            return func;
        }
    }
}

import { ConstantPool } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userToken: string;
  constructor(private router: Router) {
    console.log('this.userToken');
    this.userToken = localStorage.getItem('p_token');
  }

  logout(): void {
    this.clearSession();
    this.router.navigate(['login']);
  }

  login(token): void {
    this.setSession(token);
  }

  isLoggedIn(): boolean {
    return this.userToken ? true : false;
  }

  setSession(token): void {
    this.userToken = token;
    localStorage.setItem('p_token', token);
  }

  getSession(): string {
    if (!this.userToken) {
      this.userToken = localStorage.getItem('p_token');
    }
    return this.userToken;
  }

  clearSession(): void {
    this.userToken = undefined;
    localStorage.removeItem('p_token');
  }
}

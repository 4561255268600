import { Injectable } from '@angular/core';

interface LooseObject {
  [key: string]: any
}

@Injectable({
  providedIn: 'root'
})
export class LoadersService {
  private loadersVars: LooseObject = {};
  constructor() { }

  getExpectionalUrls() {
    return [
      'check-duplicate',
      'list?search='
    ];
  }

  setPageLoader() {
    this.loadersVars.page_load = true;
  }
  removePageLoader() {
    this.loadersVars.page_load = false;
  }

  setComponentLoader(name: string) {
    this.loadersVars[name] = true;
  }
  removeComponentLoader(name: string) {
    delete this.loadersVars[name];
  }

  isPageLoading() {
    return this.loadersVars.page_load;
  }

  isComponentLoading(name: string) {
    if (name in this.loadersVars) {
      return this.loadersVars[name] ? true : false;
    }
  }

}

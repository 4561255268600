<div mat-dialog-content >
  <ng-container *ngIf="data">
    <image-cropper 
    [imageChangedEvent]="data.imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="data.options.aspectRatio"
    [resizeToWidth]="data.options.width"
    [cropperMinWidth]="data.options.width"
    [onlyScaleDown]="true"
    [roundCropper]="false"
    format="png"
    outputType="base64"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    [style.display]="showCropper ? null : 'none'"
  ></image-cropper>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
  <button mat-button (click)="save()">Crop</button>
</div>

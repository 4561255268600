import { CommonService } from './common.service';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Login } from '../model/login';
import { Response } from '../model/response';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends CommonService {
  constructor(private http: HttpClient, injector: Injector) {
    super(injector);
  }

  login(credentials: Login): Observable<any> {
    return this.http.post('/login', credentials).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  getCategoriesList(queryParams, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get('/categories', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  getWorkshop(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get(`/workshops/${id}`), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /** Trainer APIs */
  getTrainersList(queryParams, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get('/trainers', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }
  getTrainerDetails(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/trainers/' + id), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  saveTrainer(data, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/trainers', data), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  updateTrainer(id, data, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.put('/trainers/' + id, data),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  deleteTrainer(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.delete('/trainers/' + id), loaderId).pipe(
      map((data: Response) => {
        return data;
      })
    );
  }

  /** Workshop APIs */
  getWorkshopList(queryParams, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get('/workshops', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  getWorkshopNames(queryParams, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get('/workshop/list', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  enrollWorkshop(postData, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/enroll', postData), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /**
   * Add interest for workshop
   * @param postData 
   * @param loaderId 
   * @returns 
   */
  addInterestForWorkshop(postData, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/interest', postData), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  getWorkshopDetails(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/workshops/' + id), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  storePayment(postData, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/payment', postData), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  saveWorkshop(data, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/workshops', data), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  updateWorkshop(id, data, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.put('/workshops/' + id, data),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  deleteWorkshop(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.delete('/workshops/' + id), loaderId).pipe(
      map((data: Response) => {
        return data;
      })
    );
  }
  sendFeedbackEmails(id, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get('/workshop/send-feedback-emails/?id=' + id),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /** Workshop APIs end */

  /** Category APIs  */
  getCategories(): Observable<any> {
    return this.http.get('/categories').pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }
  /** Category APIs end  */

  /** Participants APIs  */
  getParticipantsList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/participants', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /** Interest List  */
  getInterestList(queryParams: any, loaderId = null): Observable<any> {
    console.log(queryParams);
    return this.loaderCall(
      this.http.get<any>('/interests', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  archiveInterest(id, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/interest/archive?id=' + id, {}), loaderId).pipe(
      map((data: Response) => {
        return data;
      })
    );
  }

  getParticipantsListForDropDown(
    queryParams: any,
    loaderId = null
  ): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/participant/list', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  exportParticipantsList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/participant/export', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }


  exportInterestsList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/interest/export', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }
  /** Payments APIs  */
  getPaymentsList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/payments', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  exportPaymentsList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/payment/export', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* profile API */

  getAdminProfile(loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get<any>('/me'), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* Feedback API */

  createFeedback(data, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.post('/feedbacks', data), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  getFeedbacksList(queryParams: any, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.get<any>('/feedbacks', { params: queryParams }),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* Country API */

  getCountryList(loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/countries'), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* Position API */

  getPositionsList(loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/positions'), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* Institution types API */

  getInstitutionTypeList(loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/institution-types'), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /**
   * mail API
   */
  sendEmailToParticipants(data, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.post('/participant/send-email-to-participants', data),
      loaderId
    ).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /**
   * Api call for hide workshop from web site
   * @param workshopId id of the workshop
   * @param loaderId loader iderntifer
   * @returns Observable
   */
  hideWorkshop(workshopId, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.put('/workshops/' + workshopId + '/hide-workshop', {}), loaderId
    )
  }

  /**
   * Api call for show workshop in the web site
   * @param workshopId id of the workshop
   * @param loaderId loader iderntifer
   * @returns Observable
   */
  showWorkshop(workshopId, loaderId = null): Observable<any> {
    return this.loaderCall(
      this.http.put('/workshops/' + workshopId + '/show-workshop', {}), loaderId
    )
  }

  /* Order status API */

  getOrderStatus(orderId, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/site/check-order-status?orderId=' + orderId), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }

  /* Retry payment */
  retryPayment(orderId, loaderId = null): Observable<any> {
    return this.loaderCall(this.http.get('/site/retry-payment?orderId=' + orderId), loaderId).pipe(
      map((data: Response) => {
        return data.data;
      })
    );
  }
}

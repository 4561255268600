import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  CSVtoArray(csvString, delimiter = ","): Array<any> | null {
    if (!csvString || !csvString.length)
      return [];

    const pattern = new RegExp(
      ("(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"
      ), "gi"
    );

    let rows = [[]];
    let matches;

    while (matches = pattern.exec(csvString)) {

      const matched_delimiter = matches[1];
      const matched_cellQuote = matches[2];
      const matched_cellNoQuote = matches[3];

      /*
       * Edge case: Data that starts with a delimiter
       */
      if (matches.index == 0 && matched_delimiter)
        rows[rows.length - 1].push("");

      /*
       * Fix empty lines
       */
      if (!matches[2] && !matches[3])
        continue;

      if (matched_delimiter.length && matched_delimiter !== delimiter)
        rows.push([]);

      const matched_value = (matched_cellQuote)
        ? matched_cellQuote.replace(
          new RegExp("\"\"", "g"), "\""
        )
        : matched_cellNoQuote;

      rows[rows.length - 1].push(matched_value);

    }

    return rows;
  };

  downloadTemplate(template: Array<Array<string>>, name: String) {

    const csvContent =  template.map(e => e.join(',')).join('\n');
    const encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' +encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name + '.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  }

}

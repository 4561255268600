import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, ApiService } from 'src/app/core/services';
import { AdminHeaderService } from 'src/app/core/services/admin-header.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminHeaderComponent implements OnInit {
  adminUser:any
  searchForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private adminHeaderService: AdminHeaderService,
    private auth: AuthService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.getProfile()
    this.searchForm = this.fb.group({
      search: [null],
    });
  }
  submit(): void {
    console.log('okay');
  }
  logout(): void {
    this.auth.logout();
  }

  onValueChange($event): void {
    this.adminHeaderService.searchChange(this.searchForm.getRawValue());
  }

  getProfile(): void {
    this.api.getAdminProfile()
      .subscribe(
        data => {
          this.adminUser = data;
        }
      );
  }

}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-admin-nav-bar',
  templateUrl: './admin-nav-bar.component.html',
  styleUrls: ['./admin-nav-bar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminNavBarComponent {
  constructor() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionComponent } from './version/version.component';
import { InputComponent } from './form-elements/input/input.component';
import { ButtonLoaderComponent } from './loaders/button-loader/button-loader.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageModalComponent } from './image-upload/image-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SelectComponent } from './form-elements/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextAreaComponent } from './form-elements/text-area/text-area.component';
import { RadioButtonComponent } from './form-elements/radio-button/radio-button.component';
import { CheckboxComponent } from './form-elements/checkbox/checkbox.component';
import { TelephoneInputComponent } from './form-elements/telephone-input/telephone-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
@NgModule({
  declarations: [
    ButtonLoaderComponent,
    InputComponent,
    VersionComponent,
    ImageUploadComponent,
    ImageModalComponent,
    DateTimePickerComponent,
    SelectComponent,
    TextAreaComponent,
    RadioButtonComponent,
    CheckboxComponent,
    TelephoneInputComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    NgxIntlTelInputModule
  ],
  exports: [
    ButtonLoaderComponent,
    InputComponent,
    VersionComponent,
    ImageUploadComponent,
    DateTimePickerComponent,
    SelectComponent,
    TextAreaComponent,
    RadioButtonComponent,
    CheckboxComponent,
    TelephoneInputComponent
  ]
})
export class ComponentModule {}


import {Component, Inject} from "@angular/core";
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ImageCroppedEvent} from "ngx-image-cropper";

@Component({
  selector: 'image-modal-component',
  templateUrl: 'image-modal.component.html',
})
export class ImageModalComponent {

  croppedImage:any;
  showCropper:any;

  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed () {
    console.log('Load failed');
  }

  save() {
    this.dialogRef.close(this.croppedImage);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css']
})
export class TextAreaComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() displayName: any;
  @Input() minLength: any;
  @Input() maxLength: any;
  @Input() cols: any;
  @Input() placeholder: any;
  @Input() inputClass: any;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() displayName: any;
  @Input() minLength: any;
  @Input() maxLength: any;
  @Input() uniqueLoader: any;
  @Input() placeholder: any;
  @Input() inputClass: any;
  @Output() valueChange = new EventEmitter();
  @Output() blurInput = new EventEmitter();

  constructor() {
    this.inputClass = '';
  }

  ngOnInit() {}
  keyDown($event) {
    // Prevent first space
    if (!this.inputFormControl.value) {
      $event.preventDefault();
    } else {
      this.inputFormControl.patchValue(
        this.inputFormControl.value.toString().trim()
      );
    }
  }
  keyUp($event) {
    if (this.inputFormControl.value) {
      this.valueChange.emit(this.inputFormControl.value.toString());
    } else {
      this.valueChange.emit('');
    }
  }
  trimValue() {
    if (this.inputFormControl.value) {
      if (this.inputFormControl.value.toString().endsWith(' ')) {
        this.inputFormControl.patchValue(
          this.inputFormControl.value.toString().trim()
        );
      }
    }
    this.blurInput.emit();
  }
}
